<template>
  <v-app id="app">
    <NoJs />
    <v-main>
      <slot></slot>
    </v-main>
    <ConfirmDialog />
    <KeyboardShortcutsHelp />
    <!-- <AcceptTerms /> -->
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AcceptTerms from "~/components/account-alerts/AcceptTerms.vue";

export default defineComponent({
  components: { AcceptTerms },
});
</script>
